define('vault-admin/routes/organizations/show/new-dairy', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model(params) {
            let m = this.modelFor('organizations.show');
            let dairy = this.store.createRecord('dairy');
            dairy.set('organization_id', m.id);
            return dairy;
        },
        actions: {
            async createDairy() {
                let m = this.modelFor(this.routeName);
                try {
                    await m.save();
                    this.transitionTo('dairies.show', m);
                } catch (err) {
                    console.log(err);
                }
            },
            cancel() {
                let m = this.modelFor(this.routeName);
                m.rollbackAttributes();
                this.transitionTo('organizations.show.dairies');
            }
        }
    });
});