define('vault-admin/routes/integrations/show/keys', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model() {
            let integration = this.modelFor('integrations.show');
            return Ember.RSVP.hash({
                integration: integration,
                integration_keys: this.get('store').query('integration-key', { integration_id: integration.id })
            });
        },

        actions: {
            deleteKeyPair(key) {
                key.destroyRecord();
            }
        }
    });
});