define('vault-admin/routes/dairies/show/settings', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        actions: {
            async deleteDairy() {
                let m = this.modelFor(this.routeName);
                try {
                    let user_id = m.get('user_id');
                    let organization_id = m.get('organization_id');

                    await m.destroyRecord();

                    if (organization_id) {
                        // DGC: We'll probably want to update this once we have Ember Data fully wired.
                        this.transitionTo('organizations.show.dairies', organization_id);
                    } else {
                        // DGC: This isn't correct, but it also isn't possible to get here at the moment.
                        this.transitionTo('users', user_id);
                    }
                } catch (err) {
                    console.log(err);
                }
            }
        }
    });
});