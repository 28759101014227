define('vault-admin/controllers/application', ['exports', 'vault-admin/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),
    userId: Ember.computed('session.data.authenticated.id_token', function () {
      let token = this.get('session.data.authenticated.id_token');
      let base64Url = token.split('.')[1];
      let base64 = base64Url.replace('-', '+').replace('_', '/');
      return JSON.parse(window.atob(base64)).sub;
    }),
    actions: {
      async invalidateSession() {
        await fetch(`${_environment.default.APP.API_HOST}/oauth/logout?redirect_uri=${window.location.hostname}`, {
          credentials: 'include'
        });
        await this.get('session').invalidate();
        return this.transitionTo('login');
      }
    }
  });
});