define('vault-admin/routes/organizations/show/new-integration', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model() {
            let m = this.modelFor('organizations.show');
            let integration = this.store.createRecord('integration');
            integration.set('organizationId', m.id);
            integration.set('isPublic', true);
            return integration;
        },
        actions: {
            async createIntegration() {
                let m = this.modelFor(this.routeName);
                try {
                    await m.save();
                    this.transitionTo('organizations.show.developer');
                } catch (err) {
                    console.log(err);
                }
            },
            cancel() {
                let m = this.modelFor(this.routeName);
                m.rollbackAttributes();
                this.transitionTo('organizations.show.developer');
            }
        }
    });
});