define('vault-admin/adapters/application', ['exports', 'ember-data', 'ember-inflector', 'vault-admin/config/environment'], function (exports, _emberData, _emberInflector, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONAPIAdapter.extend({
    session: Ember.inject.service(),

    host: _environment.default.APP.API_HOST,

    pathForType(type) {
      const underscored = Ember.String.underscore(type);
      return (0, _emberInflector.pluralize)(underscored);
    },

    headers: Ember.computed('session.data.authenticated.access_token', function () {
      return {
        'Authorization': `Bearer ${Ember.get(this, 'session.data.authenticated.access_token')}`
      };
    }),

    handleResponse() /* status */{
      // if (401 === status) {
      //   return new MaintenanceError();
      // }

      return this._super(...arguments);
    }
  });
});