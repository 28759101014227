define('vault-admin/serializers/application', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.JSONSerializer.extend({
        keyForAttribute(attr) {
            return Ember.String.underscore(attr);
        }
    });
});