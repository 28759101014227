define('vault-admin/models/user', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        email: _emberData.default.attr('string'),
        first_name: _emberData.default.attr('string'),
        last_name: _emberData.default.attr('string'),
        name: Ember.computed('first_name', 'last_name', function () {
            return `${this.get('first_name')} ${this.get('last_name')}`;
        })
    });
});