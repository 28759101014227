define('vault-admin/routes/index', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        beforeModel() /* transition */{
            this.transitionTo('organizations'); // Implicitly aborts the on-going transition.
        }
    });
});