define('vault-admin/routes/organizations/show/settings', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        actions: {
            async deleteOrganization() {
                let m = this.modelFor(this.routeName);
                try {
                    await m.destroyRecord();
                    this.transitionTo('organizations');
                } catch (err) {
                    console.log(err);
                }
            }
        }
    });
});