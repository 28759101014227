define('vault-admin/routes/integrations/show/settings', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        actions: {
            async updateIntegration() {
                let m = this.modelFor(this.routeName);
                try {
                    await m.save();
                } catch (err) {
                    console.log(err);
                }
            },
            cancelUpdateIntegration() {
                let m = this.modelFor(this.routeName);
                m.rollbackAttributes();
            },
            async deleteIntegration() {
                let m = this.modelFor(this.routeName);
                let organizationId = m.organizationId;
                try {
                    await m.destroyRecord();
                    this.transitionTo('organizations.show.developer', organizationId);
                } catch (err) {
                    console.log(err);
                }
            }
        }
    });
});