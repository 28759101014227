define('vault-admin/routes/organizations/show/access', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model() {
            let organization = this.modelFor('organizations.show');
            return Ember.RSVP.hash({
                organization: organization,
                invitations: this.get('store').query('organization-invitation', { organization_id: organization.id })
            });
        },
        actions: {
            cancelInvitation(model) {
                model.destroyRecord();
            }
        }
    });
});