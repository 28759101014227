define('vault-admin/routes/integrations/show/new-integration-key', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model() {
            let integration = this.modelFor('integrations.show');
            let integrationKey = this.store.createRecord('integrationKey');
            integrationKey.set('integration_id', integration.id);
            return integrationKey;
        },
        actions: {
            async createIntegrationKey() {
                let m = this.modelFor(this.routeName);
                try {
                    await m.save();
                    this.transitionTo('integrations.show.keys');
                } catch (err) {
                    console.log(err);
                }
            },
            cancel() {
                let m = this.modelFor(this.routeName);
                m.rollbackAttributes();
                this.transitionTo('integrations.show.keys');
            }
        }
    });
});