define('vault-admin/router', ['exports', 'vault-admin/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('organizations', function () {
      this.route('show', { path: '/:organization_id' }, function () {
        this.route('settings', { path: '/settings' });
        this.route('access', { path: '/access' });
        this.route('developer', { path: '/developer' });
        this.route('dairies', { path: '/dairies' });
        this.route('new_dairy', { path: '/dairies/new' });
        this.route('new_invitation', { path: '/invite' });
        this.route('new_integration', { path: '/integrations/new' });
      });
      this.route('new', { path: '/new' });
    });
    this.route('callback');
    this.route('login');

    this.route('dairies', function () {
      this.route('show', { path: '/:dairy_id' }, function () {
        this.route('settings');
      });
    });

    this.route('integrations', function () {
      this.route('show', { path: '/:integration_id' }, function () {
        this.route('settings', { path: '/settings' });
        this.route('keys', { path: '/public_keys' });
        this.route('new_integration_key', { path: '/public_keys/new' });
      });
    });

    this.route('users', function () {
      this.route('show', { path: '/:user_id' }, function () {
        this.route('integrations');
      });
    });
  });

  exports.default = Router;
});