define('vault-admin/routes/login', ['exports', 'vault-admin/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        activate() {
            let redirectURI = `${window.location.origin}/callback`;
            let responseType = `id_token`;
            let scope = `email`;
            window.location.replace(`${_environment.default.APP.API_HOST}/oauth/authorize?` + `redirect_uri=${redirectURI}` + `&response_type=${responseType}` + `&scope=${scope}`);
        }
    });
});